

















































































































































import {Component, Vue, Prop, Watch, Ref} from 'vue-property-decorator';
import gql from 'graphql-tag';
import {ObjectiveDetailedFragment, UpdateObjective} from '@/graphql/objective';
import SmartDateTime from "@/components/general/SmartDateTime.vue";
import QuickDateSelector from "@/components/board/objective/QuickDateSelector.vue";
import {Objective as ObjectiveObject} from "@/typescript/types";
import chrono from 'chrono-node';
import {NorthStar} from "@/typescript/types";
import NorthStarService from "@/NorthStarService";
import WorkableItem from "@/components/general/WorkableItem.vue";
import {SingleNorthStar} from "@/graphql/NorthStar";
import FreemiumLimitation from '@/components/subscription/FreemiumLimitation.vue';
import { WorkspaceQuery } from '@/graphql/workspace';
import VerifyUser from '@/components/auth/VerifyUser.vue';
import RoomTutorial from '@/components/auth/demo/RoomTutorial.vue';


@Component({
  components: {
    WorkableItem,
    QuickDateSelector, 
    SmartDateTime,
    FreemiumLimitation
  },
})
export default class GuidedCreateObjective extends Vue {

  @Ref('freemiumLimitationRef') readonly freemiumLimitationComponent!: FreemiumLimitation;

  createObjectiveActive: boolean = false;
  selectedSuggestions: Array<string> = [];
  newObjectiveInput: string | null = '';
  assignedPriority: string = 'normal';
  submitted: boolean = false;
  startDate: Date | null = null;
  dueDate: Date | null = null;
  endDate: string | null = null;
  discardNaturalLanguageDate: boolean = false;
  showSuggestions: boolean = false;
  suggestedDateFromInput: Date | null = null;
  name: string = '';
  wait: boolean = false;
  error: boolean = false;
  service: NorthStarService | null = null;
  northstarData: NorthStar | null = null;

  todoItems = [
    "e.g. go for a run",
    "e.g. organize desk",
    "e.g. create meal plan",
    "e.g. call mom",
    "e.g. review weekly budget",
    "e.g. read for 20 minutes",
    "e.g. declutter my room",
    "e.g. respond to work email",
    "e.g. update calendar",
    "e.g. 10 minute meditation",
    "e.g. take a walk",
    "e.g. meal prep",
    "e.g. clean kitchen",
    "e.g. call a friend",
    "e.g. check emails",
    "e.g. water plants",
    "e.g. write in journal",
  ];

  @Prop({default: null, required: true})
  startDateFromSprint!: Date;

  @Prop({default: null, required: false})
  dueDateFromSprint!: Date;

  @Prop({default: undefined, required: false})
  northStar!: NorthStar;

  @Prop({default: true, required: false})
  showDateIndicator: boolean | null | undefined;

  @Prop({default: true, required: false})
  focusOnMount: boolean | null | undefined;

  @Prop({required: false, default: true})
  showChronoDate!: boolean;

  @Prop({required: false, default: false})
  inModal!: boolean;

  @Prop({required: false, default: null })
  color!: string | null;

  @Prop({required: false, default: null})
  boardId!: string | null

  @Prop({required: false, default: null})
  fromUnassigned!: string | null


  mounted() {
    this.$gtag.event('pmb_app_guided_create_objective_mounted');
    // this.setStartDate()

   
    if(this.$props.northStar) {
     
      if(this.$props.northStar.objective_suggestions && this.$props.northStar.objective_suggestions.length > 0) {
        this.northstarData = this.$props.northStar;
      }
      // else{
      //   this.northstarWithSuggestions();
      //
      // }
    }

    this.getOpenState();

    if(this.focusOnMount) {
      this.$nextTick(function(){
        if(this.$refs.oInput) {
          //@ts-ignore ToDo: fix type issue
          this.$refs.oInput.focus();
        }
      });
    }


    // if(this.dueDateFromSprint && !this.northStar) {
    //   this.dueDate = this.dueDateFromSprint;
    // }

    this.calculateStartDate();

    this.service = new NorthStarService(this.$apollo);
  }



  calculateStartDate() {
    
    if(this.fromUnassigned) {
      this.startDate = this.startDateFromSprint
    } else {
      this.startDate = null;
    }
    
  }




  get accountAge() {
    const userCreationDate = new Date(this.me.created_at); 
    const currentDate = new Date(); 
    const differenceInTime = currentDate.getTime() - userCreationDate.getTime(); 
    const differenceInDays = differenceInTime / (1000 * 3600 * 24); 
    return differenceInDays ;
  }

  get colorFromNs() {
    if(this.$props.northStar) {
      return this.$props.northStar.color;
    } else {
      return 'grey';
    }
  }

  get placeholderText() {
    const randomIndex = Math.floor(Math.random() * this.todoItems.length);
    return this.todoItems[randomIndex];
  }


  get board() {
    return this.$store.state.board;
  }

  get me() {
    return this.$store.state.me;
  }

  get isDueDateBeforeStartDate() {
    if (!this.startDate || !this.dueDate) {
      return false;
    }
    return this.dueDate < this.startDate;
  }

  get workspace() {
    return this.$store.state.workspace;
  }

  get activitesCreated() {
    return this.workspace.totalObjectivesCount
  }

  get isPremium() {
    if(this.$store.state.workspace.currentSubscription) {
      return true
    } else { return false }
  }

  get currentlyOnboarding() {
    return !!(this.$store.state.currently_onboarding || this.$store.state.currently_onboarding_in_room);
  }

  get onGuestAccount() {
    return this.$store.state.me.isFake;
  }

 


  getOpenState() {
    if (this.$store.state.newlyCreatedNortstarInBoard === this.northStar?.id ) {
      this.createObjectiveActive = true;
    }
  }

  focusInput() {
    this.$nextTick(function(){
      if(this.$refs.oInput) {
        //@ts-ignore ToDo: fix type issue
        this.$refs.oInput.focus();
      }
    });
  }

  ifAlreadySelected(objectiveId: string) {
    return this.selectedSuggestions.includes(objectiveId);
  }

  northstarWithSuggestions() {
    this.$apollo.query({
      query: SingleNorthStar,
      variables: {
        goal_id: this.$props.northStar.id
      }
    }).then((result: any) => {
      if(result) {
        this.northstarData = result.data.getNorthStar;
      }
    })
  }
  handleSuggestionStartDate() {
    this.startDate = this.suggestedDateFromInput
    this.$buefy.toast.open({
      message: 'Start date updated!',
      position: 'is-bottom-right',
      type: 'is-black',
    });
  }

  handleSuggestionDueDate() {
    this.dueDate = this.suggestedDateFromInput
    this.$buefy.toast.open({
      message: 'Due date updated!',
      position: 'is-bottom-right',
      type: 'is-black',
    });
  }


  get dateFromInput() {
    /*
    if (this.newObjectiveInput && this.showChronoDate) {
      if (chrono.parseDate(this.newObjectiveInput)) {
        this.suggestedDateFromInput = chrono.parseDate(this.newObjectiveInput);
      }
      return chrono.parseDate(this.newObjectiveInput);
    }
    return null;

     */
    if (this.newObjectiveInput && chrono.parseDate(this.newObjectiveInput)) {
      this.suggestedDateFromInput = chrono.parseDate(this.newObjectiveInput);
      return chrono.parseDate(this.newObjectiveInput);
    } else {
      return null
    }
  }

  get claimAccountPromptCount() {
    return this.$store.state.claim_account_prompt_count;
  }

  openDatePicker(type: string) {
    let date;
    if(type === 'due' && this.dueDate) {
      date = this.dueDate;
      date.setHours(17, 0, 0, 0);
    } else if(type === 'start' && this.startDate) {
      date = this.startDate;
      date.setHours(9, 0, 0, 0);
    } else {
      date = new Date();
      date.setHours(17, 0, 0, 0);
    }

    this.$buefy.modal.open({
      component: SmartDateTime,
      width: '280px',
      // @ts-ignore
      customClass: 'is-paddingless',
      parent: this,
      props: { 'remove-due-date': true, 'set-date': date},
      events: {
        //@ts-ignore ToDo: fix type issue
        'date': value => {
          if(type === 'due') {
            if(value) {
              this.dueDate = value;
            } else {
              this.dueDate = null;
            }
          } else if(type === 'start'){
            if(value) {
              this.startDate = value;
            } else {
              this.startDate = null;
            }
          }
          this.focusInput();
        }
      },
    });
  }

  toggleCreateActive() {
    this.createObjectiveActive = !this.createObjectiveActive

    if(this.createObjectiveActive) {
      this.$nextTick(() => {
        if (this.$refs.oInput) {
        //@ts-ignore ToDo: fix type issue
          this.$refs.oInput.focus();
        }
      });
    }
  }

  setStartDate(): void {
    /**
     * Set tomorrow as due datetime
     * */
    let today = new Date();
    if (this.startDateFromSprint) {
      this.startDate = this.startDateFromSprint;
    } else {
      this.startDate = today;
    }
  }

  hasError() {
    this.error = true;
    setTimeout(() => { this.error = false }, 1000);
  }

  copyObjective(objective_id: string, northstar_id: string) {
    this.$gtag.event('pembio_create_objective_from_suggestion');
    let boardId;
    if(this.$store.state.board) {
      boardId = this.$store.state.board.id;
    } else if(this.northstarData && this.northstarData.boards && this.northstarData.boards.length >= 1) {
      boardId = this.northstarData.boards[0].id
    } else {
      boardId = undefined;
    }
    if(boardId) {
      this.selectedSuggestions.push(objective_id);
      this.service?.copyObjectiveForNorthStar(northstar_id, objective_id, boardId).then((res: any) => {
        this.$events.fire('objective-created-from-northstar', {objective: res.data.copyObjectiveForNorthstar, northStarId: northstar_id });
        
        this.setDateToCopiedObjective(res.data.copyObjectiveForNorthstar.id);
        if(this.$store.state.currently_onboarding_in_room) {
          this.$store.commit('set_onboarding_activities_added', true);
        }
      });
    }
  }

  setDateToCopiedObjective(id: string) {
    this.$apollo.mutate({
      mutation: UpdateObjective,
      variables: {
        id: id,
        start_date: this.startDate,
        date: this.dueDate
      }
    })
  }

  createObjective() {
  // Check if we should prompt to claim account
    if (this.claimAccountPromptCount >= 0 && 
      this.onGuestAccount && 
      (this.activitesCreated !== 0 && this.activitesCreated % 3 === 0) &&
      !this.isPremium && 
      !this.currentlyOnboarding) {
      this.$events.fire('claim-account-highlight');
    } else {
    // Check freemium status if component is available
      if (this.freemiumLimitationComponent) {
        this.freemiumLimitationComponent.checkFreemiumStatus();
      }
    }
  
    this.submitted = true;

    // Update onboarding state if necessary
    if (this.$store.state.currently_onboarding_in_room) {
      this.$store.commit('set_onboarding_activities_added', true);
    }

    // Track event for first 12 objectives
    if (this.activitesCreated <= 12) {
      this.$gtag.event('pmb_add_objective_number_' + this.activitesCreated);
    }

    // Call the mutation to create the objective
    this.createObjectiveMutation();
  }

  createObjectiveMutation() {

    if(this.wait) {
      return
    }
    this.wait = true;
    setTimeout(() => this.wait = false, 1000);

    if(!this.newObjectiveInput) {
      this.hasError();
    }
    return this.$apollo
      .mutate({
        mutation: gql`
                    mutation createObjective(
                        $owner_id: ID!
                        $name: String!
                        $board_id: ID!
                        $start_date: DateTime
                        $due_date: DateTime
                        $priority: ObjectivePriority
                        $background_color: String
                    ) {
                        createObjective(
                            input: {
                                owner: { connect: $owner_id }
                                board: { connect: $board_id }
                                name: $name
                                start_date: $start_date
                                due_date: $due_date
                                priority: $priority
                                background_color: $background_color
                            }
                        ) {
                            ...ObjectiveDetailed
                        }
                    }
                    ${ObjectiveDetailedFragment}
                `,
        variables: {
          owner_id: this.me.id,
          name: this.newObjectiveInput,
          board_id: this.$props.boardId ? this.$props.boardId : this.board.id,
          start_date: this.startDate,
          end_date: this.endDate,
          due_date: this.dueDate,
          priority: this.assignedPriority,
        },
        refetchQueries: [{query: WorkspaceQuery, variables: { pid: this.$store.state.workspace.pid }}]
      })
      .then((result) => {
        
        if(this.$props.northStar){
          this.attachNorthStar(result.data.createObjective, this.$props.northStar);
          result.data.createObjective['from_northstar'] = true;
        }else{
          this.$events.fire('objective-created', result.data.createObjective);
        }

        this.$emit('submitted', true);
        this.$emit('objective-created-from-sprint', result.data.createObjective);
        this.$events.fire('objective-is-new', result.data.createObjective.id);
        this.$store.commit('set_new_objective', result.data.createObjective.id);
        setTimeout(() => { 
          this.$store.commit('set_new_objective', null);
        }, 1000);
        this.$events.fire('open-objective', result.data.createObjective)
        this.submitted = false;
        this.$gtag.event('pembio_create_objective');
        this.calculateStartDate();
       
      })
      .finally(() => {
        this.newObjectiveInput = null; 
        this.$nextTick(function(){
          if(this.$refs.oInput) {
          //@ts-ignore ToDo: fix type issue
            this.$refs.oInput.focus();
          }
        });
       
        
      });
  }

  attachNorthStar(objective: ObjectiveObject, northStar: NorthStar){
    this.service?.addNorthStarToObjective(objective, [northStar]).then(res => {
      this.$events.fire('objective-created-from-northstar', {objective: res.data.updateObjective, northStarId: northStar.id });
      return;
    });
  }

  openClaimAccount() {
    this.$gtag.event('pmb_user_claim_account_from_page_blocker');
    this.$buefy.modal.open({
      component: VerifyUser,
      props: {
        claimWs: (this.$store.state.me.isFake) ?? false,
        clickedClaim: true,
        canBypass: false,
        activateFeedback: true,
      },
      width: '500px',
      canCancel: false,
      parent: this,
    });
  }

  openComplete() {
    this.$buefy.modal.open({
      component: RoomTutorial,
      width: '800px',
      parent: this,
      customClass: 'room_onboarding_modal',
      canCancel: false,
    });
  }

  openVerifyUser() {
    this.$nextTick(function () {
      this.$buefy.modal.open({
        component: VerifyUser,
        props: {
          claimWs: (this.$store.state.me.isFake) ?? false,
          clickedClaim: true,
          signUpWithEmail: true
        },
        width: '500px',
        canCancel: false,
        parent: this,
      });
    });
  }
  
  @Watch('startDateFromSprint')
  onStartDateFromSprintChange() {
    this.setStartDate();
  }
  
  @Watch('northStar.start_date', { immediate: true, deep: true })
  onNorthStarChange(newVal: NorthStar, oldVal: NorthStar) {
    if (newVal !== oldVal) {
      this.calculateStartDate();
    }
  }

 

}

