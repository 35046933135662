



































































































import {Component, Mixins, Prop, Watch} from 'vue-property-decorator';
import Objective from '@/components/board/objective/Objective.vue';
import GuidedCreateObjective from "@/components/board/objective/GuidedCreateObjective.vue";
import {Container, Draggable} from "vue-smooth-dnd";
import {Objective as ObjectiveObject} from "@/typescript/types";
import {formatDistance} from "date-fns";
import {ObjectiveNotificationMixin} from "@/mixins/ObjectiveNotificationMixin";
import WorkableItem from "@/components/general/WorkableItem.vue";
import CreateObjectiveTrigger from "@/components/objectives/CreateObjectiveTrigger.vue";
import { isPast } from "date-fns";

@Component({
  components: {
    CreateObjectiveTrigger,
    WorkableItem,
    Objective,
    GuidedCreateObjective,
    Container,
    Draggable,
  },
})
export default class Sprint extends Mixins(ObjectiveNotificationMixin) {
  loveBombItem: string | null = null;

  @Prop({default: false, required: true})
  objectivesList!: ObjectiveObject[];

  @Prop({default: null, required: false})
  dueDate!: Date;

  @Prop({default: null, required: false})
  startDate!: Date;

  @Prop({default: false, required: false})
  title!: String;

  @Prop({default: false, required: false})
  containerName!: String;

  @Prop({default: true, required: false})
  isBoardMember!: boolean;

  @Prop({default: false, required: false})
  canCreateObjective!: boolean

  @Prop({default: false, required: false})
  acceptDrop!: boolean

  @Prop({default: false, required: false})
  isLoading!: boolean

  @Prop({default: undefined, required: false})
  totalPaginatedItems!: Number;

  //isMember: boolean = true;

  clientCurrentDate: string | null = null;
  activeBoardId: string | null = null;
  isCompletedObjective: boolean = false;
  minimizeDueByDefault: boolean = true;
  categoryCollapsed: boolean = true;
  defaultColor: string | null = '#5f5f5f';

  get unassignedLoading() {
    return (this.$props.title == 'unassigned_northstar' && this.isLoading) ? true : false;
  }

  mounted() {
    if(this.title === 'due') {
      this.defaultColor = '#a91a47';
      this.categoryCollapsed = false;
    }
  }

  loveBomb(reaction: string) {
    this.loveBombItem = reaction;
    setTimeout(() => {
      this.loveBombItem = null;
    }, 1400);
  }

  get board() {
    return this.$store.state.board;
  }

  get templateOnboarding() {
    return this.$store.state.template_onboarding;
  }


  forHumans(d: Date) {
    return formatDistance(new Date(d), new Date());
  }

  hasPassedDueDate(date: Date) {
    return isPast(new Date(date));
  }


  openSpotlight() {
    this.$buefy.modal.open({
      component: GuidedCreateObjective,
      props: {
        dueDateFromSprint: this.dueDate,
        startDateFromSprint: this.startDate,
        focusOnMount: true,
        inModal: true,
        showChronoDate: (this.containerName === 'dateRange') ? false : true
      },
      events: {
        'objective-created-from-sprint': (objective: ObjectiveObject) => {
          // this.$emit('objective-created-from-category', {category: this.title, objective: objective})
          this.objectiveCreated(objective);
        },
        'objective-updated': (objective: ObjectiveObject) => {
          this.objectiveCreated(objective);
        },
      },
      // @ts-ignore
      customClass: 'create-new-objective blurless',
      fullScreen: true,
      parent: this,
    });
  }

  onDragStart(dragResult: any): void {
    this.$emit('on-drag-start', dragResult);
  }

  onDragStop(dragResult: any): void {
    this.$emit('on-drag-stop', dragResult)
  }

  objectiveCreated(objective: ObjectiveObject): void {
    this.$emit('objective-created', objective)
  }

  onDrop(dropResult: any,containerName: any): void {
    if (this.objectivesList) {
      this.applyDrag(dropResult,containerName);
    }
  }

  getChildPayload(index: number) {
    if (this.objectivesList) {
      return this.objectivesList[index]
    }
  }

  getGhostParent() {
    return document.body;
  }

  cleanSource(source: ObjectiveObject) {
    /**
     * using native JSON functions removes reactivity
     * so we can clone an object without mutating the original source
     */
    return JSON.parse(JSON.stringify(source));
  }

  applyDrag(dragResult: any, containerName: any): void {
    let {removedIndex, addedIndex, payload} = dragResult
    if (removedIndex === null && addedIndex === null || !payload) return;


    if (!this.objectivesList) {
      return;
    }

    if((removedIndex == null && addedIndex != null) || (removedIndex != null && addedIndex == null)) {
      if(containerName != 'due' && containerName != 'completed') {
        if(payload.recurrences.length <= 0) {
          payload['start_date'] = this.$props.startDate;
          payload['due_date'] = this.$props.dueDate;
          this.$emit('drag', {
            addedIndex: addedIndex,
            removeIndex: removedIndex,
            payload: payload,
            containerName: containerName
          });
        }

      }
    }


    // if (removedIndex != null && addedIndex != null) {
    //   this.$emit('drag', {
    //     addedIndex: addedIndex,
    //     removeIndex: removedIndex,
    //     payload: payload,
    //     sortContainerName: containerName
    //   });
    //
    // } else {
    //   this.$emit('drag', {
    //     addedIndex: addedIndex,
    //     removeIndex: removedIndex,
    //     payload: payload,
    //     containerName: containerName
    //   });
    // }

  }

  statusUpdated(value: any){
    let index = this.objectivesList.findIndex((obj: any) => obj.id === value.objId);
    this.objectivesList[index].status = value.status;

    if(this.checkAllCompleted()){
      //@ts-ignore
      this.$refs['sprintDiv'].classList.add('all_completed_animation');
      //@ts-ignore
      //setTimeout(() => this.$refs['sprintDiv'].classList.remove('all_completed_animation'), 3000);
    }else if(value.status === 'completed' && !this.checkAllCompleted()){
      this.isCompletedObjective = false;
      //@ts-ignore
      this.$refs['sprintDiv'].classList.add('increment_progress');
      //@ts-ignore
      //setTimeout(() => this.$refs['sprintDiv'].classList.remove('increment_progress'), 3000);
    }

  }

  checkAllCompleted(){
    if(this.objectivesList.filter((obj: any) => obj.status != 'completed').length > 0){
      return false;
    }
    return true;
  }

  shouldAcceptDrop (_sourceContainerOptions: any, _payload: any) {
    return this.$props.acceptDrop;
  }


  @Watch('$store.state.board', {immediate: true})
  boardChanged() {
    if (this.board && this.activeBoardId !== this.board.id) {
      this.activeBoardId = this.board.id
      // let index = this.board.members?.findIndex((member: any)=>{ return member.user.id === this.$store.state.me.id });
      // if(index != -1){
      //   this.isMember = true;
      // }else {
      //   this.isMember = false;
      // }
    }
  }

  @Watch('totalPaginatedItems', {immediate: true})
  totalPaginatedItemsChanged(newVal: number, oldVal: number) {
    
    if(newVal != oldVal && this.title != ' ') {
      if(oldVal === undefined) {
        this.$store.commit('set_total_objectives_in_timeStamp', (this.$store.getters.getTotalObjectivesInTimeStamp != null) ? this.$store.getters.getTotalObjectivesInTimeStamp + newVal : newVal)
      }else{
        if(oldVal < newVal) {
          this.$store.commit('set_total_objectives_in_timeStamp', (this.$store.getters.getTotalObjectivesInTimeStamp != null) ? this.$store.getters.getTotalObjectivesInTimeStamp + 1 : 1)
        }else{
          this.$store.commit('set_total_objectives_in_timeStamp', (this.$store.getters.getTotalObjectivesInTimeStamp != null) ? this.$store.getters.getTotalObjectivesInTimeStamp - 1 : 0)
        }

      }

    }

  }
}
