






  
import {Component, Vue} from 'vue-property-decorator';
import {Role} from "@/typescript/types";
import HardBlock from './HardBlock.vue';
import FirstOffer from './FirstOffer.vue';
import FirstFeedback from './FirstFeedback.vue';
import {feedback_types} from "@/graphql/feedback";
import {CreateFeedbackMutation} from '@/graphql/feedback';
import { isAfter, addHours } from 'date-fns';


  
@Component({
  apollo: {
    feedback_types: {
      query: feedback_types,
      result(_result) {
        this.feedbackTypeId = _result.data.feedback_types[0].id;
      }
    },
  },
  components: {},
})
export default class FreemiumLimitation extends Vue {

  // @Prop({default: null, required: true})
  // activitesCreated!: Number; 
  feedbackDescription: string = "User: " + this.me.name + "  Email: " + this.me.email + " User: " + this.me.name + "  Email: " + this.me.email + " Total activites: " + this.activitesCreated + " Account created: " + this.me.created_at
  response: string = "";
  feedbackTypeId: string = ""
  timeCheck: number = 0;
 

  get me() {
    return this.$store.state.me;
  }

  get workspace() {
    return this.$store.state.workspace;
  }

  get activitesCreated() {
    return this.workspace.totalObjectivesCount
  }

  get sessionCount() {
    return this.$store.state.me.unique_day_session_count;
  }

  get isPremium() {
    if(this.$store.state.workspace.currentSubscription) {
      return true
    } else { return false }
  }

  get onGuestAccount() {
    return this.$store.state.me.isFake;
  }

  get isPrimaryOwner() {
    return (this.workspace.my_role === Role.PrimaryOwner) ? true : false;
  }

  get newUser() {
    let userCreationDate = new Date(this.me.created_at.split('T')[0]);
    let comparisonDate = new Date('2024-04-14');
    return userCreationDate.getTime() > comparisonDate.getTime();
  }

  

  get accountAge() {
    const userCreationDate = new Date(this.me.created_at);
    const currentDate = new Date();
  
    // Check if the current date is at least 5 minutes after the user creation date
    return isAfter(currentDate, addHours(userCreationDate, 12));
  }

  get trialEnded()  {
    return this.$store.state.trial_ended
  }

  get firstOfferShown() {
    return this.$store.state.first_offer_shown
  }


  get hasFreemiumAccess() {
    return this.me.has_freemium_access
  }

  get workspaceId() {
    return this.$store.state.workspace.id;
  }

  get meId() {
    return this.$store.state.me.id;
  }

  get showFirstOffer() {
    return this.$store.state.show_first_offer;
  }

  

  mounted() {
   
  }

  submitFeedback() {
    this.$gtag.event('pmb_user_feedback_submitted');
    this.$apollo
      .mutate({
        mutation: CreateFeedbackMutation,
        variables: {
          creator_id: this.meId,
          workspace_id: this.workspaceId,
          feedback_type_id: this.feedbackTypeId,
          title: "User has gotten first offer",
          description: this.feedbackDescription
        },
      })
      .then(() => {
        this.$gtag.event('pmb_user_plan_opt_out');
        this.feedbackDescription = "";
        this.$gtag.event('pmb_user_trial_offer_mounted');
      });
  }


  openHardBlock() {
    this.$buefy.modal.open({
      component: HardBlock,
      // @ts-ignore
      customClass: 'split-modal',
      parent: this,
      canCancel: false,
    });
  }

  openFirstOffer() {
    this.$buefy.modal.open({
      component: FirstOffer,
      // @ts-ignore
      customClass: 'split-modal',
      parent: this,
      canCancel: false,
    });
  }

  openFirstFeedback() {
    this.$buefy.modal.open({
      component: FirstFeedback,
      parent: this,
      width: '700px',
      canCancel: false,
  
    });
  }

  checkFreemiumStatus() {
   
    if(!this.isPremium  && !this.$store.state.currently_onboarding && !this.$store.state.currently_onboarding_in_room) {

      // if(this.activitesCreated === 4 && !this.onGuestAccount ) { 
      //   this.submitFeedback() 
      // }

      if((this.hasFreemiumAccess === null || this.hasFreemiumAccess === false) && !this.onGuestAccount && this.accountAge && this.showFirstOffer) {
        this.submitFeedback();
        this.openFirstOffer();
        this.$store.commit('set_show_first_offer', null);

      }
     
      else { return }



     
    }

  


  // @Watch('')
  // onActivitesCreatedChanged() {
  // // checks if user is not premium, is signed up after Feb 22 2024, and is not currently onboarding
  //   if(!this.isPremium && this.newUser && !this.$store.state.currently_onboarding && !this.$store.state.currently_onboarding_in_room) {
  //     if(this.activitesCreated === 5) {     
  //       setTimeout(() => {
  //         this.$store.commit('set_first_offer_shown', true);
  //         this.openFirstOffer();
  //       }, 2000);
  //     }
  //     else if(this.activitesCreated === 10 && this.sessionCount > 2 && !this.trialEnded ) { 
  //       setTimeout(() => {
  //         this.openHardBlock();
  //       }, 2000);
  //     } else { return }
  //   }
  }
  

}
